import { UserRole } from "@/lib/types/user";
import { DoctorMenu } from "./doctorMenu";
import { NotDoctorButUserMenu } from "./nonDoctorButUserMenu";
// import { NonUserMenu } from "./NonUserMenu";

interface NavMenuTopProps {
  role: UserRole;
}
export const NavMenuTop = ({
  role
}: NavMenuTopProps) => {
  switch (role) {
    case "doctor":
      return <DoctorMenu />;
    // case "non_user":
    //   return <NonUserMenu />;
    case "admin":
    default:
      return <NotDoctorButUserMenu />;
  }
};