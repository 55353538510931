"use client";

import { ReactNode, useState } from "react";
import { PersistentHeader } from "@/components/persistentHeaderBar/persistentHeader";
import { NavMenuTop } from "@/components/globalNavMenu/navMenuTop";
import { NavMenuBottom } from "@/components/globalNavMenu/navMenuBottom";
import { TooltipProvider } from "@/components/ui/tooltip";
import { useSession } from "next-auth/react";
import { CreateTaskNote } from "@/components/taskNotes/createTaskNote";
import { NavigationGuardProvider } from "next-navigation-guard";
import dynamic from "next/dynamic";
import { PHProvider } from "@/lib/layouts/posthogProvider";
interface LayoutProps {
  children: ReactNode;
}
const PostHogPageView = dynamic(() => import("@/components/posthog/posthogPageView"), {
  ssr: false
});
export const MainLayout = ({
  children
}: LayoutProps) => {
  const [notesOpen, setNotesOpen] = useState(false);
  const {
    data: session
  } = useSession();
  return <PHProvider data-sentry-element="PHProvider" data-sentry-component="MainLayout" data-sentry-source-file="mainLayout.tsx">
      <TooltipProvider delayDuration={300} data-sentry-element="TooltipProvider" data-sentry-source-file="mainLayout.tsx">
        <NavigationGuardProvider data-sentry-element="NavigationGuardProvider" data-sentry-source-file="mainLayout.tsx">
          <PostHogPageView data-sentry-element="PostHogPageView" data-sentry-source-file="mainLayout.tsx" />
          <div className="flex min-h-screen [&_div[data-lastpass-icon-root]]:hidden [&_div[data-lastpass-infield]]:hidden">
            <div className="flex h-screen w-14 flex-col justify-between bg-primary-fg-blue [@media(min-height:700px)]:w-[3.75rem] [@media(min-height:850px)]:w-20">
              <NavMenuTop role={session!.user.role} data-sentry-element="NavMenuTop" data-sentry-source-file="mainLayout.tsx" />
              <NavMenuBottom role={session!.user.role} data-sentry-element="NavMenuBottom" data-sentry-source-file="mainLayout.tsx" />
            </div>

            <div className="flex h-screen min-h-0 min-w-0 flex-1 flex-col bg-[#f0f2f5]">
              <PersistentHeader setNotesOpen={setNotesOpen} data-sentry-element="PersistentHeader" data-sentry-source-file="mainLayout.tsx" />
              {children}
              <CreateTaskNote isOpen={notesOpen} setOpen={setNotesOpen} data-sentry-element="CreateTaskNote" data-sentry-source-file="mainLayout.tsx" />
            </div>
          </div>
        </NavigationGuardProvider>
      </TooltipProvider>
    </PHProvider>;
};