"use client";

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { ComponentProps } from "react";
import { cn } from "@/lib/utils/cssUtils";
const Avatar = ({
  className,
  ...props
}: ComponentProps<typeof AvatarPrimitive.Root>) => <AvatarPrimitive.Root className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)} {...props} data-sentry-element="unknown" data-sentry-component="Avatar" data-sentry-source-file="avatar.tsx" />;
Avatar.displayName = AvatarPrimitive.Root.displayName;
const AvatarImage = ({
  className,
  ...props
}: ComponentProps<typeof AvatarPrimitive.Image>) => <AvatarPrimitive.Image className={cn("aspect-square size-full", className)} {...props} data-sentry-element="unknown" data-sentry-component="AvatarImage" data-sentry-source-file="avatar.tsx" />;
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
const AvatarFallback = ({
  className,
  ...props
}: ComponentProps<typeof AvatarPrimitive.Fallback>) => <AvatarPrimitive.Fallback className={cn("flex size-full items-center justify-center rounded-full bg-gray-700 text-lg font-bold text-white", className)} {...props} data-sentry-element="unknown" data-sentry-component="AvatarFallback" data-sentry-source-file="avatar.tsx" />;
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
export { Avatar, AvatarImage, AvatarFallback };