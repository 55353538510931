"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren, useEffect } from "react";
export const PHProvider = ({
  children
}: PropsWithChildren) => {
  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      person_profiles: "identified_only",
      capture_pageview: false // Disable automatic pageview capture, as we capture manually
    });
  }, []);
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthogProvider.tsx">{children}</PostHogProvider>;
};