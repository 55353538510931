"use client";

import { useQuery } from "@tanstack/react-query";
import { Table as SCNTable, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import { SortableDataTableColumnHeader } from "@/components/ui/sortableDataTableColumnHeader";
import { NoteType } from "@/lib/types/encounterNote";
import { ColumnDef, SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
// import { ViewFileDialog } from "../viewFileDialog/viewFileDialog";
import { format as formatFNS, parseISO } from "date-fns";
import { CustomSpinner } from "@/components/ui/customSpinner";
import userAPI from "@/api/userAPI";
import { isNil } from "lodash";
import dynamic from "next/dynamic";
import useUserQuery from "@/lib/utils/hooks/useUserQuery";
const ViewFileDialog = dynamic(() => import("@/components/viewFileDialog/viewFileDialog"), {
  ssr: false
});
const fallbackData: NoteType[] = [];
interface NotificationsTableProps {
  setNotificationsModalVisible?: Dispatch<SetStateAction<boolean>>;
}
export const NotificationsTable = ({
  setNotificationsModalVisible
}: NotificationsTableProps) => {
  const {
    data: user
  } = useUserQuery();
  const [viewFileDialogOpen, setViewFileDialogOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [noteDataIndex, setNoteDataIndex] = useState<number | null>(null);

  // only thing this is getting called is for the loading state and this way I dont have to prop drill...
  const newDoctorFilesQueryObject = useQuery({
    queryKey: ["newDoctorFiles"],
    queryFn: () => userAPI.getNewDoctorFilesList(),
    select: data => {
      const noteData: NoteType[] = data.filter(file => !file.archived).map((file, index: number) => ({
        id: file.id,
        index: index,
        doctorName: file.doctorName,
        title: file.title,
        type: file.documentType,
        date: file.date.slice(0, 10),
        data: {
          file: file.file
        },
        reason: file.reason,
        doctorId: file.doctorId,
        archived: file.archived,
        resolved: file.resolved,
        patientName: file.patientName,
        patientId: file.patientId,
        key: `file_note_${index}`
      }));
      return noteData;
    },
    enabled: !isNil(user?.id) && user.type !== "admin",
    refetchInterval: 60 * 1000 // Refetch every 5 minutes while user is logged in
  });
  const notificationsColumnDef: ColumnDef<NoteType>[] = useMemo(() => [{
    accessorKey: "patientName",
    header: ({
      column
    }) => <SortableDataTableColumnHeader column={column} title="Patient Name" />
  }, {
    accessorKey: "date",
    header: ({
      column
    }) => <SortableDataTableColumnHeader column={column} title="Date" />,
    cell: ({
      row
    }) => {
      return <div className="flex justify-center">{formatFNS(parseISO(row.getValue("date")), "yyyy-MMM-dd")}</div>;
    },
    enableResizing: false,
    size: 130
  }, {
    accessorKey: "type",
    // header: "Type",
    header: ({
      column
    }) => <SortableDataTableColumnHeader column={column} title="Type" />,
    enableResizing: false,
    size: 180
  }, {
    accessorKey: "title",
    header: ({
      column
    }) => <SortableDataTableColumnHeader column={column} title="Document Name" />,
    cell: ctx => <div className="flex h-full items-center justify-between py-2">
            <div className="">{ctx.row.getValue("title")}</div>
          </div>,
    enableResizing: false,
    size: 350
  }], [user]);
  const tableObject = useReactTable({
    data: newDoctorFilesQueryObject.data ?? fallbackData,
    columns: notificationsColumnDef,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    filterFns: {
      fuzzy: (row, columnId, value, addMeta) => true
    },
    state: {
      sorting
    },
    autoResetAll: false
  });
  useEffect(() => {
    if (tableObject) {
      tableObject.getColumn("date")?.toggleSorting(true);
    }
  }, [tableObject]);
  const onSelectRow = async (noteIndex: number, note: NoteType) => {
    setNoteDataIndex(noteIndex);
    setViewFileDialogOpen(true);
  };

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   */
  const columnSizeVars = useMemo(() => {
    const headers = tableObject.getFlatHeaders();
    const colSizes: {
      [key: string]: number;
    } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      // console.log(`header size: ${header.getSize()}`);
      // console.log(`header column ${header.column.id} size: ${header.column.getSize()}`);
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [tableObject.getState().columnSizingInfo]);
  return <>
      <ViewFileDialog viewFileDialogOpen={viewFileDialogOpen} setViewFileDialogOpen={setViewFileDialogOpen} noteDataIndex={noteDataIndex} setNoteDataIndex={setNoteDataIndex} noteData={structuredClone(tableObject.getRowModel().rows.map(v => v.original))} setNotificationsModalVisible={setNotificationsModalVisible} data-sentry-element="ViewFileDialog" data-sentry-source-file="notificationsTable.tsx" />
      <SCNTable style={columnSizeVars} data-sentry-element="SCNTable" data-sentry-source-file="notificationsTable.tsx">
        <TableHeader data-sentry-element="TableHeader" data-sentry-source-file="notificationsTable.tsx">
          {tableObject.getHeaderGroups().map(headerGroup => <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
            return <TableHead key={header.id} style={{
              width: `calc(var(--header-${header?.column?.id}-size) * 1px)`
            }}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>;
          })}
            </TableRow>)}
        </TableHeader>
        <TableBody data-sentry-element="TableBody" data-sentry-source-file="notificationsTable.tsx">
          {newDoctorFilesQueryObject.isLoading && <TableRow className="">
              <TableCell colSpan={tableObject.getAllColumns().length} className="h-[500px]">
                <CustomSpinner spinnerSize="xlarge" />
              </TableCell>
            </TableRow>}
          {newDoctorFilesQueryObject.isSuccess && (tableObject.getRowModel().rows?.length ? tableObject.getRowModel().rows.map((row, rowIndex) => <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} className="hover:bg-primary-hover">
                  {row.getVisibleCells().map(cell => <TableCell key={cell.id} className="cursor-pointer"
          // onClick={() => cell?.column?.id !== "moreActions" && onSelectRow(rowIndex)} // onSelectRow(row.original as NoteType)}
          onClick={() => onSelectRow(rowIndex, row.original as NoteType)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>)}
                </TableRow>) : <TableRow>
                <TableCell colSpan={tableObject.getAllColumns().length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>)}
        </TableBody>
      </SCNTable>
    </>;
};